<template>
  <div id="dashboard-content">
    <div class="alert bg-primary-50 border-0 py-3">
      <div class="row">
        <div class="col-md-9">
          <h6 class="font-weight-bold text-dark">
            Selamat datang di dashboard Pelaku Perbukuan.
          </h6>
          <span class="text-grey-400"
            >Untuk panduan lengkap, silakan unduh materi berikut ini</span
          >
        </div>
        <div class="col-md-3 mt-2">
          <a
            href="https://docs.google.com/document/d/1vtp0vKWhtXCL7TlKbDF5GovzV-BDHYT4/edit?rtpof=true&sd=true"
            target="_blank"
            class="d-flex align-items-center justify-content-center btn btn-sm btn-outline-primary h-100 w-100"
            >
              <img src="@/assets/dashboard/icon/Panduan.svg" alt="" /> 
              Buku Panduan
          </a>
        </div>
      </div>
    </div>

    <!-- Carousel -->
    <div id="carouselBanner" class="carousel slide" data-ride="carousel">
      <div v-if="mode == 'desktop'" class="carousel-inner">
        <div v-for="(banner, index) in bannersDesktop" :key="index" :class="`carousel-item ${index == 0 && 'active'}`">
          <img class="d-block w-100" height="250px" :src="banner" :alt="banner">
        </div>
      </div>
      <div v-else class="carousel-inner">
        <div v-for="(banner, index) in bannersMobile" :key="index" :class="`carousel-item ${index == 0 && 'active'}`">
          <img class="d-block w-100" :src="banner" :alt="banner">
        </div>
      </div>
      <a class="carousel-control-prev" href="#carouselBanner" role="button" data-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="false"></span>
        <span class="sr-only">Previous</span>
      </a>
      <a class="carousel-control-next" href="#carouselBanner" role="button" data-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="false"></span>
        <span class="sr-only">Next</span>
      </a>
    </div>
    <!-- End Carousel -->
  </div>
</template>

<style scoped>
.carousel-control-next-icon:after {
  content: "" !important;
  font-size: 55px;
}

.carousel-control-prev-icon:after {
  content: "" !important;
  font-size: 55px;
}
</style>

<script>
export default {
  name: "Home",
  data() {
    return {
      user: [],
      mode: window.screen.width < 576 ? 'mobile' : 'desktop',
      bannersDesktop: [
        'https://image.web.id/images/2022/06/08/58a861a50d09ca16614a616b83d02aeb.jpg',
        'https://image.web.id/images/pemberitaan-seputar-kemendikbudristek.jpg',
        'https://image.web.id/images/surat-edaran-ppkn.jpg',
        'https://image.web.id/images/2022/07/04/c53fdb85ab1696365d716dfff40495f4.jpg'
      ],
      bannersMobile: [
        'https://image.web.id/images/pemberitaan-kemendikbudristek-mobile.jpg',
        'https://image.web.id/images/surat-edaran-tentang-revisi-buku-ppkn-7-mobile.jpg',
        'https://image.web.id/images/banner-mobile-5.jpg',
        'https://image.web.id/images/banner-mobile-3.jpg'
      ]
    };
  },
  created() {
    const logged_in = localStorage.getItem("user");
    if (!logged_in) {
      this.$router.push("/login");
    }
    this.user = JSON.parse(logged_in);
  },
};
</script>
